.img-account-profile {
  height: 12rem;
  border-radius: 10px;
}


h5 {
  font-weight: 550;
  margin-top: 20px;
  font-size: 1rem;
}

h4.header {
  margin-top: 1rem;
  border-bottom: 1px solid #e0e0e0;
  line-height: 3rem;
}
