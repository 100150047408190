.input-group {
  padding: 15px 0;
}

.image-container{
  display:flex;
  flex-direction: row;

}

.image-component{
  display:flex;
  align-items: end;
}

.file-container {
  border: none !important;
  padding: 0;
  display: none !important;
}

.image-preview {
  border-radius: 10px;
  height: 150px;
  width: 150px;
  object-fit: cover;
  margin-right: 20px;
}
