@import 'bootstrap';
@import 'assets/styles/colors';

html,
body {
  margin: 0;
  padding: 0;
  display: block;
  height: 100% !important;
  font-family: "NeueHaasDisplay-Roman", sans-serif !important;
  font-style: normal !important;
}

.centered-button {
  align-self: flex-start;
  width: 203px;
  background: $metallic-yellow;
  border-radius: 8px;
  border: none;
  padding: 16px;
  text-decoration: none;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: $eerie-black;
  margin-top: 32px;
}

.background {
  display: flex;
}

.waves-style {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  margin-right: 0% !important;
}

.left-side-text {
  vertical-align: middle;
}

.split-subtitle {
  margin-top: 16px;
}

@media (min-width: 320px) {
  .left-side {
    width: 100%;
    text-align: center !important;
  }

  .split-title h1 {
    margin-top: 120px;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.02em;
  }

  .split-subtitle h4 {
    line-height: 30px;
    font-size: 18px;
    line-height: 23px;
  }
}

@media (min-width: 375px) {
  .left-side {
    width: 100%;
    text-align: center;
    padding: 20px 30px 0;
  }

  .split-subtitle {
    text-align: center;
    margin-bottom: 32px;
  }

  .split-title h1 {
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.02em;
  }

  .split-subtitle h4 {
    font-weight: 300;
    font-size: 22px;
    line-height: 30px;
  }
}

@media (min-width: 425px) {
  .left-side {
    padding: 20px 70px 0;
  }
}

@media (min-width: 600px) {
  .left-side div {
    margin-top: 0;
    width: 100%;
    padding: 0;
    text-align: left;
  }

  .split-subtitle {
    text-align: left;
    margin-bottom: 0;
  }

  .split-title {
    margin-top: 0;
  }

  .split-title h1 {
    font-weight: 500;
    font-size: 72px;
    line-height: 80px;
  }

  .split-subtitle h4 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-top: 16px;
  }
}

@media (min-width: 1024px) {
  .split-subtitle {
    text-align: left;
    margin-bottom: 0;
  }

  .split-title h1 {
    margin-top: 50px;
  }

  .split-subtitle h4 {
    margin-top: 16px;
  }
}

.yellow-part {
  background-color: #FCDF5E;
  background-image: url('../../assets/images/ridethewaves.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.klika-logo {
  width: 30%
}

.klika-text {
  align-items: left;
  margin-top: 20vh;
}