.ProfileCard {}

p.no-user-select {
  user-select: none;
}

span {
  user-select: text;
}

@media print {
  .print-hidden {
    display: none;
  }
}