@import 'assets/styles/typography';

.background-green {
  background-color: green;
}

.bg-dark {
  background-color: #212529;
}

.brand-text {
  padding-left: 0.5rem;
  padding-top: 0.6rem;
}

.content {
  background-color: white;
  border: 1px solid #dee2e6;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  min-height: calc(100vh - 6rem);
  height: fit-content;
}

.mt-2 {
  margin: 0 !important;
}

.nav-icon {
  font-size: 1.2rem;
  margin-left: 0.05rem;
  margin-right: 0.2rem;
  text-align: center;
  width: 1.6rem;
}

.nav-link span {
  color: #c2c7d0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.nav-link:focus {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3%;
  color: #fff;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.sidebar {
  color: #212529;
  font-family: $font-family-primary;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  max-height: 100vh !important;
}

.sidebar-brand,
.navbar {
  height: 3.5rem !important;
}

nav {
  position: -webkit-sticky !important;
  /* Safari */
  position: sticky !important;
  z-index: 1;
}

.sidebar-dark {
  background-color: #343a40;
}

.sidebar-hidden {
  display: none;
}

a.nav-link {
  padding: 8px 16px;
}

body {
  background-color: #fff;
  color: #212529;
  font-family: $font-family-primary;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;
}

hr {
  margin: 0 16px;
}

#sidebarToggle {
  background-color: #fff;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.content-card {
  margin-top: 5rem !important;
}

.mr-nav-open {
  margin-right: 14rem !important;
}

.mr-nav-collapsed {
  margin-right: 6.5rem !important;
}

.mr-nav-none {
  margin-right: 0 !important;
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
  width: 100%;
}

.navbar {
  z-index: 100000 !important;
}

.form-max-width {
  max-width: 600px !important;
}

.nav-tabs .nav-link:hover {
  color: rgb(28, 28, 28);
}

.custom-input-color {
  border: 1px solid #ced4da !important;
}