.person-img {
  width: 80px;
  border-radius: 50%;
  border: 1px solid #e3e6f0;
  margin-top: 10px;
}
.person-card {
  height: 100% !important;
  align-items: center;
  text-align: center;
}
.person-card .card-title {
  font-weight: 400;
}
.person-card .card-body {
  padding: auto;
}
::-webkit-scrollbar {
  height: 5px;
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: darkgray;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.team-lead .person-card {
  background-color: #eff0f2;
}
.dark-rounded-border {
  border: 1px solid #e3e6f0;
  border-radius: 5px;
}
.dashboard-greeting p {
  font-size: 1rem;
  margin-bottom: 10px;
}
