.toast-error {
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
}

.toast-success {
  background-color: #d4edda;
  color: #155724;
  border-color: #c3e6cb;
}
